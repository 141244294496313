import React from "react";
import styled from "styled-components";
import Block from "../../components/Block/Block";
import { Button } from "../../uiux/Button";
import { transferLinksData } from "../../utils/datas/transferLinksData";
import useSetting from "../../utils/hooks/useSetting";

export interface TransferPage {
  className?: string;
  browserType?: string;
}

const TransferPage: React.FC<TransferPage> = ({ className }: TransferPage) => {
  const { browserType } = useSetting();

  return (
    <TransferWrapper
      className={`${className} flex flex-col items-center`}
      browserType={browserType}
    >
      <Block
        margin={browserType === "CBOL" ? "49px 0 34px" : "63px 0 0"}
        title="Where to buy PCX"
        titleSize={browserType === "CBOL" ? "40px" : "32px"}
        titleLine={
          browserType === "CBOL"
            ? {
              width: "100px",
              height: "1px",
              color: "rgba(0, 0, 0, 0.2)",
              paddingTB: "35px",
            }
            : {
                width: "48px",
                height: "3px",
                color: "rgba(254, 227, 43, 1)",
                paddingTB: "22px",
              }
        }
        content={
          <>
            <p>Where to buy PCX ?</p>
            <p>You can buy PCX directly from the exchange.</p>
          </>
        }
        contentColor="rgba(0, 0, 0, 0.56)"
        className="to-coming"
      />
      <div className="transfer-wrap flex justify-center items-center">
        {transferLinksData.children.map((item) => {
          return (
            <Button
              imgButton={item.imgButton}
              linkTo={item.linkTo}
              width={transferLinksData.itemWidth}
              height={transferLinksData.itemHeight}
              radius={transferLinksData.itemRadius}
              border={transferLinksData.border}
              backColor={transferLinksData.backColor}
              hoverBackColor={transferLinksData.hoverBackColor}
              shadowColor={transferLinksData.shadowColor}
              hoverShadowColor={transferLinksData.hoverShadowColor}
              shadowHeight={transferLinksData.shadowHeight}
              className="transfer-button"
            />
          );
        })}
      </div>
    </TransferWrapper>
  );
};

export default TransferPage;

export const TransferWrapper = styled.div<TransferPage>`
  display: flex;
  flex: 1;
  .transfer-wrap {
    max-width: 1362px !important;
    flex-wrap: wrap;
    .transfer-button {
      margin: 0 75px 70px;
    }
    margin-bottom: 40px;
  }
  ${({ browserType }) => {
    switch (browserType) {
      case "MBOL":
        return `
        .transfer-wrap{
          margin: -60px 0;
          transform: scale(0.6);
          width:620px !important;
          .transfer-button {
            margin: 16px 12px !important;
          }
        }
        `;
      default:
        return `
        
        `;
    }
  }}
`;
