import * as React from "react";
import { Wrappers, Content, Title, Introduce, IntroduceTitle, ItemTitle, LittleContent } from "./style"
import { Line } from "../../uiux/Line";

interface CardContentProps {
    className: string;
    background?: string;
    color?: string;
    title?: string;
    introduceClass?: string;
    opposite: boolean;
    imgPic: string;
    littleTitle?: string;
    littleTitleColor?: string
    line?: React.ReactNode;
    itemTitle?: string;
    littleContent?: string[];
    button?: React.ReactNode;
}

const CardContent = ({ className = '', background, color, title, introduceClass, opposite, imgPic, littleTitle, littleTitleColor, line, itemTitle, littleContent, button }:CardContentProps) => {
  return (
    <Wrappers id={`${introduceClass}`} className={`flex items-center ${className}`} style={{"background":background,"color":color}}>
        {title && <Title className="web">{title}</Title>}
        <Content className={`flex items-center ${introduceClass} ${opposite ? 'flex-row-reverse' : 'contentR' }`}>
            <img src={imgPic} className="imgPic" alt={imgPic}/>
            <Introduce className={`${introduceClass}`}>
                {title && <div className="mobTitle"><Title className="mob">{title}</Title><Line className="line" height="1px" color='#000' paddingTB="20px" opacity='0.2' /></div>}
                {littleTitle && <IntroduceTitle className={`${littleTitleColor}`}>{littleTitle}</IntroduceTitle>}
                {line} 
                {itemTitle && <ItemTitle>{itemTitle}</ItemTitle>}
                {littleContent && littleContent.map((content)=>{
                    return <LittleContent key={content} className={`little${introduceClass}`}>{content}</LittleContent> 
                })}    
                {button}
            </Introduce>
        </Content>
    </Wrappers>
  )
};

export default CardContent