import styled from "styled-components";

export const FooterWrapper = styled.div`
    background: #0B0F12;
    color: #fff;
    font-size: 14px;
    padding: 4.6vw 8.8vw;
    .contain {
        @media screen and (min-width: 1400px) {
            width: 1280px;
            margin: auto;
        }
        @media screen and (max-width: 850px) {
            flex-direction: column;
            align-items: center;
        }
        @media screen and (max-width: 550px) {
            padding: 9.3vw 11.2vw;
            font-size: 14px;
            align-items: inherit;
        }    
    }
    .content {
        @media screen and (max-width: 550px) {
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: baseline;
        }
        .item {
            line-height: 20px;
            margin-right: 7vw;
            @media screen and (max-width: 550px) {
                width: 50%;
                margin: 0 0 26px 0;    
                justify-content: center;
                align-items: baseline;
            }
            .title {
                white-space: nowrap;
                font-weight: 600;
                margin-bottom: 30px;
                @media screen and (max-width: 550px) {
                    margin-bottom: 18px;   
                }
            }
            .titleItem {
                position:relative;
                user-select: none;
                &:before,
                &:after {
                content: "";
                position: absolute;
                width: 0px;
                height: 2px;
                margin: 5px 0 0;
                transition: all .3s cubic-bezier(0.340, 0.370, 0.055, 1.560);
                opacity: 1;
                background-color: #fff;
                left: 0;
                bottom: -6px;
                }
                &:hover {
                    &:before, &:after {
                        width: 100%;
                        opacity: 1;
                    }
                }
            }
            .list {
                font-weight: 400;
                div {
                    margin-bottom: 12px;
                    white-space: nowrap;
                } 
            }
        }
    }
    .Introduce {
        @media screen and (min-width: 550px) and (max-width: 1080px) {
            align-items: flex-end;
            justify-content: space-around;
        }
        @media screen and (min-width: 1080px) {
        }
        .logo {
            margin-bottom: 6px;
        }
        .allRight {
            margin-top: 70px;
            .reserved {
                font-weight: 400;
                line-height: 20px;
                margin: 16px 0;
                white-space: nowrap;
                opacity: 0.32;
                @media screen and (max-width: 1080px) {
                    margin: 16px 0 0px;
                }
            }
            @media screen and (max-width: 550px) {
                margin-top: 22px;
            }
            .littleContent {
                .imgIcon {
                    margin-right: 10px;
                    opacity: 0.6;
                    &:hover {
                        opacity: 1;
                    }
                }
                .iconwechats {
                    position: relative; 
                    .codes {
                      position: absolute;
                      bottom: 32px;
                      left: 0;
                      display: none;
                      width: 150px;
                      height: 150px;
                    }
                    &:hover .codes {
                      display: block;
                    }
                }
                .copytoggle {
                    position: relative;
                    .copyemails {
                        width: 150px;
                        height: 42px;
                        line-height: 42px;
                        color: #fff;
                        background: #E2B534;
                        text-align: center;
                        border-radius: 4px;
                        position: absolute;
                        bottom: 40px;
                        right: 0;
                        .arrow {
                            position: relative;
                            &:after{
                                content: "";
                                border: 10px solid transparent;
                                border-top-color: #E2B534;
                                position: absolute;
                                bottom: -32px;
                                right: -10px;
                            }
                        }  
                    }
                }
            }
        }
    }
`;

export const DoubleLink = styled.div`
  width: 50%;
  >div:nth-child(1){
    margin-bottom: 20%;
  }
  @media screen and (max-width: 550px) {
    position: relative;
    top:-16vw;
  }
`;

export const SplitLine = styled.div`
  border-left: 0.5px solid white;
  height: 229px;
  opacity: 0.17;
  transform: translateX(-60px);
  @media screen and (max-width: 550px) {
   display: none;
  }
`
