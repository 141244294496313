import React from "react";
import Swiper from "../../lib/Swiper";
import ModeTitles from "../ModeTitles";
import { BannerWrapper } from "./style";

const Banner = () => {
  return (
      <BannerWrapper className="banner relative">
        <Swiper />
        <ModeTitles />
      </BannerWrapper>
  );
};

export default Banner;
