import React from "react";
import { HomeWrapper } from "./style";
import CardContent from "../../components/CardContent";
import CustodialMode1 from "../../assets/img/CustodialMode.svg"
import CustodialMode2 from "../../assets/img/taproot.svg"
import NonCustodialMode from "../../assets/img/img_lighting.svg"
import ComingChat from "../../assets/img/comingchat.svg"
import Net from "../../assets/img/net.webp"
import { Line } from "../../uiux/Line";
import Block from "../../components/Block/Block";
import { homeCardData } from "../../utils/datas/homeCardData" 
import { Img } from "../../uiux/Img";
import { Button } from "../../uiux/Button";
import Banner from "../../components/Banner";
import HoverLink from "../../lib/HoverLink";

const HomePage: React.FC = () => {

  return (
    <HomeWrapper>
      <Banner />
      <CardContent className='py-150 px-11'
       background="#fff"
       color="#000"
       introduceClass="CustodialMode"
       opposite={false}
       imgPic={CustodialMode1} 
       littleTitle={'Custodial Mode -1'}
       littleTitleColor={'black'}
       line={<Line className="line" height="1px" color='#000' paddingTB="20px" opacity='0.2' />}
       itemTitle="V1.0  Light node + multi-signature custody scheme"
       littleContent={[
       'We assembled Bitcoin light nodes for the first time with Wasm technology, which supports transaction proof verification and POW verification of Bitcoin block headers, with detrusted relay chain as the bridge for notification.',
       'The implementation of light nodes on ChainX adopts the same consensus verification algorithm as the Bitcoin chain in verifying all block headers of the original chain, and ensuring data security through transaction roots and transaction signatures.']}
      />
      <CardContent className='py-164 px-11'
       background="#000"
       color="#fff"
       opposite={true}
       introduceClass="CustodialModes"
       imgPic={CustodialMode2} 
       littleTitle={'Custodial Mode -2'}
       littleTitleColor={'yellow'}
       line={<Line className="line" height="1px" color='#FEE32B' paddingTB="20px" opacity='0.5' />}
       itemTitle="V2.0  Threshold Signature Custody Scheme"
       littleContent={[
       'What remains the same with version 1.0 is that BTC withdrawal requires multiple signatures of the custodians to complete the asset transfer. What differs is that in 2.0, the threshold multi-signature technology is deployed through Taproot to replace traditional multi-signature method.', 
       'The Schnorr signature algorithm can aggregate multiple signatures into one digital signature and store it on the blockchain network. The Mast contract can achieve N/M signatures while hiding complex conditions, which greatly improves privacy protection.']}
      />
      <CardContent className='py-100 px-11'
       background="#F8F9FA"
       color="#000"
       introduceClass="NonCustodialMode"
       opposite={false}
       imgPic={NonCustodialMode} 
       littleTitle={'Non-custodial Mode'}
       littleTitleColor={'black'}
       line={<Line className="line" height="1px" color='#000' paddingTB="20px" opacity='0.2' />}
       itemTitle="X- Lighting"
       littleContent={[
       'The Lightning Network is a new protocol layer built on top of Bitcoin. It uses cutting-edge smart contracts to enable faster transaction speeds and greater throughput than Visa, while retaining the peer-to-peer nature of the Bitcoin protocol.',
       'ChainX will implement its own version of the Lightning Network protocol（X- Lighting）, by offering faster and cheaper bitcoin transactions, we will open the door to a new world of bitcoin transactions, many of which are not possible with traditional financial systems.']}
      />
      <Block
        title="How does ChainX achieve the scalability of Bitcoin?"
        titleSize="2.5rem"
        titleColor="#000"
        titleLine={{
          width: "96px",
          height: "6px",
          color: "#FEE32B",
          paddingTB: "32px",
        }}
        background="rgba(255, 255, 255, 1)"
        content="ChainX will support deployment Substrate and Ethereum smart contract projects in the future. BTC will share the role of ETH in Ethereum"
        contentSize="1.25rem"
        contentColor="#000"
        className="smartContract"
      />
      <div className="flex justify-center items-center cardList">
        <div className="flex justify-center mob">
          {homeCardData.item.map((item) => {
            return (
              <HoverLink
                width={homeCardData.itemWidth}
                height={homeCardData.itemHeight}
                radius={homeCardData.itemRadius}
                textColor={homeCardData.textColor}
                shadowColor={homeCardData.shadowColor}
                hoverShadowColor={homeCardData.hoverShadowColor}
                hoverBackColor={homeCardData.hoverBackColor}
                hoverTextColor={homeCardData.hoverTextColor}
                background={homeCardData.background}
                className="cardListLink"
              >
                <Img imgSource={item.icon} width="44px" height="45px" />
                <div className="flex flex-col innerCard">
                  <div className="title">{item.title}</div>
                  <div className="content">{item.content}</div>
                </div>
              </HoverLink>
            );
          })}
        </div>
      </div>
      <Block
        title="In the future, Dapps in various fields such as games, finance, NFT, social networking, etc., will rely on the Bitcoin ecosystem."
        titleSize="2rem"
        titleColor="#FEE32B"
        titleLine={{
          width: "96px",
          height: "6px",
          color: "#FEE32B",
          paddingTB: "32px",
        }}
        background={`#000 url(${Net}) no-repeat`}
        className="dapps"
      />
      <CardContent className='py-130 px-11'
       background="#fff"
       color="#000"
       introduceClass="ComingChat"
       title="ComingChat, a ChainX-based social media platform"
       opposite={false}
       imgPic={ComingChat}
       itemTitle="Live for six months and already has millions of users, becoming an unequivocal social traffic hub. It supports XBTC payment on the ChainX chain."
       littleContent={['ComingChat, as the entrance to this smart contract platform, is building a simple, secure, and private global payment system and financial infrastructure, with social networking functions embedded. It is deeply committed to building a meta-universe of the entire ecosystem for billions of people around the world.']}
       button={<Button className="btn" text="ComingChat" textColor="rgba(1, 1, 1, 1)" hoverTextColor="rgba(255, 255, 255, 1)" backColor="rgba(254, 227, 43, 1)" 
        hoverBackColor="rgba(1, 1, 1, 1)" shadowColor="rgba(254, 227, 43, 0.2)" hoverShadowColor="rgba(1, 1, 1, 0.2)" radius="8px" linkTo="https://www.coming.chat/" />}
      />
    </HomeWrapper>
  );
};

export default HomePage;