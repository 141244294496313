import icon_telegram from "../../assets/img/icon_telegram.png";
import icon_twitter from "../../assets/img/icon_twitter.png";
import icon_wechat from "../../assets/img/icon_wechat.png";
import icon_github from "../../assets/img/icon_github.png";
import icon_coming from "../../assets/img/icon_coming.png";
import icon_emal from "../../assets/img/icon_emal.png";
import icon_common from "../../assets/img/icon_common.png";
import icon_medium from "../../assets/img/icon_medium.png";

export const wayFindingData = {
  title: "Wayfinding",
  border: "rgba(0, 0, 0, 0.12)",
  itemWidth: "224px",
  itemHeight: "205px",
  itemRadius: "10px",
  textColor: "rgba(63, 63, 63, 1)",
  shadowColor: "rgba(0, 0, 0, .1)",
  hoverShadowColor: "rgba(105, 92, 2, .34)",
  hoverBackColor: "rgba(254, 227, 43, 1)",
  item: [
    {
      linkTo: "https://t.me/chainx_org",
      icon: icon_telegram,
      title: "Telegram",
      logoText: "Announcements",
      content: "~19,587 members",
    },
    {
      linkTo: "https://twitter.com/chainx_org",
      icon: icon_twitter,
      title: "Twitters",
      logoText: "Announcements",
      content: "~82,000 members",
    },
    {
      linkTo: "",
      icon: icon_wechat,
      title: "Wechat",
      logoText: "Announcements",
      content: "Suspend operations",
    },
    {
      linkTo: "https://github.com/chainx-org/ChainX",
      icon: icon_github,
      title: "Github",
      logoText: "Engineering",
      content: "＞ 220 stars",
    },
    {
      linkTo: "https://www.coming.chat/group/#EjcKIIF4lzZVydqYbq36JqO1dlwiW7dD1sWqw1KIFZ0gdGU9EhAx00UNYK8ktrHRdciDXqSfGOIP",
      icon: icon_coming,
      title: "ComingChat",
      logoText: "Announcements",
      content: "1000 members",
    },
    {
      linkTo: "",
      icon: icon_emal,
      title: "Email",
      logoText: "Mettings",
      content: "hi@chainx.org",
      actived: "Copied adress",
    },
    {
      linkTo: "https://gov.chainx.org/",
      icon: icon_common,
      title: "Commonwealth",
      logoText: "Discussion",
      content: "~50 members",
    },
    {
      linkTo: "https://chainx-org.medium.com/",
      icon: icon_medium,
      title: "Medium",
      logoText: "Announcements",
      content: "~3900 followers",
    },
  ],
};
