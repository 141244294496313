import styled from "styled-components";

export const MenuButtonWrapper = styled.div`

.bm-menu-wrap{
  width:100vw !important;
  top:0rem !important;
  
}
.bm-item {
  display: inline-block;
  text-decoration: none;
  color: #000;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: fixed;
  transform: translate(-5vw,5vw);
  width: 5vw;
  right: 0;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 4.8vw !important;
  top: 5.3vw !important;;
  right: 5.3vw !important;;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  // padding: 2.75vh 0 2.75vh 5.3vw;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  background-color: white;
  height: 20px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.8);
}

`;
