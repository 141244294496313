import ChainX_logo from "../../assets/img/ChainXLogo.svg";
import chainxWorknet from "../../assets/img/chainxWorknet.svg";
import sherpax from "../../assets/img/sherpax.svg";
import help from "../../assets/img/help.svg";
import helpCenter from "../../assets/img/helpcenter.svg";
import lightPaper from "../../assets/Chainx.pdf";
import chainxApps from '../../assets/img/products/icon_apps.svg'
import sherpaxSoswap from '../../assets/img/products/icon_swap.svg'
import sherpaxDAO from '../../assets/img/products/icon_BTC Dao.svg'
import minixComingChat from '../../assets/img/products/icon_coming.svg'
import minixComfuture from '../../assets/img/products/icon_comfuture.svg'
import toolchainxApps from '../../assets/img/tools/icon_chainx apps.svg'
import toolsherpaxApps from '../../assets/img/tools/icon_sherpax apps.svg'
import explorer from '../../assets/img/tools/icon_chainx explor.svg'
import explorerEvm from '../../assets/img/tools/icon_evm expolr.svg'
import explorerMini from '../../assets/img/tools/icon_minix.svg'
import walletChainx from '../../assets/img/tools/icon_wallet.svg'

export const headerData = {
  background: "#000",
  textColor: "rgba(255, 255, 255, .7)",
  hoverTextColor: "FFE742",
  imgPic: {
    imgSource: ChainX_logo,
    width: "140px",
    height: "28px",
  },
  navRouter: [
    {
      title: "Products",
      children: [
        {
          itemTitle:'ChainX',
          itemChildren:[
            {
              name: "APPs (BTC cross-chain)",
              Icon: chainxApps,
              href: "https://dapp.chainx.org/",
              content:'The main user-facing application,\n allowing access to all features\n available on blockchain. '
            }
          ]
        },
        // {
        //   itemTitle:'SherpaX',
        //   itemChildren:[
        //     // {
        //     //   name: "Soswap",
        //     //   Icon: sherpaxSoswap,
        //     //   href: "https://soswap.finance/",
        //     //   content:'The decentralized exchange with features such as Trade, Earn and multi-chain asset bridge.'
        //     // },
        //     {
        //       name: "BTC DAO（coming soon)",
        //       Icon: sherpaxDAO,
        //       href: " ",
        //       content:'built on the SherpaX blockchain. Users can use BTC, PCX and other currencies on SherpaX as collateral to generate a stable currency USB anchored to USD.'
        //     }
        //   ]
        // },
        {
          itemTitle:'MiniX',
          itemChildren:[
            {
              name: "ComingChat",
              Icon: minixComingChat,
              href: "https://coming.chat/",
              content:'A trusted social-economic metaverse. private social networking, cryptowallet,digital identity, NFT. '
            },
            {
              name: "Comfuture",
              Icon: minixComfuture,
              href: "https://comfuture.coming.chat/",
              content:'NFT\'s trading and creation platform for everyone, and cid\'s official exchange. '
            }
          ]
        }
      ],
    },
    {
      title: "Tools",
      children: [
        {
          itemTitle:'APPs',
          content:'The main user-facing application, allowing access to all features available on blockchain. ',
          itemChildren:[
            {
              name: "ChainX APPs",
              Icon: toolchainxApps,
              href: "https://dapp.chainx.org/",
            },
            // {
            //   name: "SherpaX Apps",
            //   Icon: toolsherpaxApps,
            //   href: "https://apps.sherpax.io/#/accounts",
            // }
          ]
        },
        {
          itemTitle:'Explorer',
          content:'Explore the network transactions, blocks, and nodes on blockchain. ',
          itemChildren:[
            {
              name: "ChainX Explorer",
              Icon: explorer,
              href: "https://scan.chainx.org/",
            },
            // {
            //   name: "SherpaX Explorer",
            //   Icon: explorer,
            //   href: "https://scan.sherpax.io/",
            // },
            // {
            //   name: "SherpaX-EVM Explorer",
            //   Icon: explorerEvm,
            //   href: "https://evm.sherpax.io/",
            // },
            {
              name: "MiniX Explorer",
              Icon: explorerMini,
              href: "https://miniscan.coming.chat/",
            }
          ]
        },
        {
          itemTitle:'Wallets',
          content:'Store, send, receive, and stake PCX on desktop, mobile. ',
          itemChildren:[
            {
              name: "ChainX Wallet",
              Icon: walletChainx,
              linkTo: "/wallet",
            }
          ]
        }
      ],
    },
    {
      title: "Community",
      path: "/community",
    },
    {
      title: "Doc",
      children: [
        {
          name: "Light paper",
          Icon: help,
          href: lightPaper,
        },
        {
          name: "Help Center",
          Icon: helpCenter,
          href: "https://chainx-org.github.io/documentation",
        },
      ],
    },
    {
      title: "Token",
      path: "/transfer",
    },
  ],
  // mobileLink: [
  //   {
  //     name: "ChainX Wallet",
  //     link: "/wallet",
  //   },
  //   {
  //     name: "Block Scan",
  //     href: "https://scan.chainx.org",
  //   },
  //   {
  //     name: "Get PCX",
  //     link: "/transfer",
  //   },
  //   {
  //     name: "Community",
  //     link: "/community",
  //   },
  //   {
  //     name: "ChainX Network",
  //     href: "",
  //   },
  //   {
  //     name: "SherpaX Network",
  //     href: "https://sherpax.chainx.cc",
  //   },
  //   {
  //     name: "Light paper",
  //     href: lightPaper,
  //   },
  //   {
  //     name: "Help Center",
  //     href: "https://chainx-org.github.io/documentation",
  //   },
  // ],
};
