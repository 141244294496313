import React, { useEffect, useState } from 'react';
import { DoubleLink, FooterWrapper, SplitLine } from './style';
import { Img } from '../../uiux/Img';
import { footerData } from '../../utils/datas/footerData';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export interface FooterProps {
  className?: string;
}


const Footer: React.FC<FooterProps> = ({className}: FooterProps) => {

  const [emails, setEmails] = useState('chainxbtclayer2@gmail.com');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setEmails('comingchat@google.com');
  }, []);

  useEffect(() => {
    const timer = setInterval(() => setCopied(false), 3001);
    return () => {
      clearInterval(timer);
    };
  }, [copied]);

  const getFooterLink = (friendLinks: any) => {
    return (<>
      {friendLinks.title && <div className="flex flex-col item" key={friendLinks.title}>
        {friendLinks.path ? <Link to={friendLinks.path} className="flex items-baseline">
          <div className="title titleItem">{friendLinks.title}</div>
        </Link> : <div className="title">{friendLinks.title}</div>}
        <div className="list flex flex-col items-baseline">
          {friendLinks.children.map((item: any) => {
            return <div key={item.name} className="cursor-pointer">
              {item.linkTo ? <Link to={item.linkTo}>
                  <div className="titleItem">{item.name}</div>
                </Link> :
                <a href={item.href && item.href} target={`${item.href && '_blank'}`}
                   rel="noopener noreferrer">
                  <div className="titleItem">{item.name}</div>
                </a>}
            </div>;
          })}
        </div>
      </div>}
    </>);
  };

  return (
    <FooterWrapper className={`${className} flex justify-between`}>
      <div className="flex justify-between contain">
        <div className="content flex ">
          {footerData.friendLinks.map((friendLinks) => {
            return (<>
              {friendLinks.title && getFooterLink(friendLinks)}
              {!friendLinks.title &&
                <DoubleLink>
                  {friendLinks.children && getFooterLink(friendLinks.children[0])}
                  {friendLinks.children && getFooterLink(friendLinks.children[1])}
                </DoubleLink>
              }
            </>);
          })}
        </div>
        <SplitLine/>
        <div className="flex flex-col Introduce">
          <Link to="/">
            <Img {...footerData.imgPic} className="logo cursor-pointer"/>
          </Link>
          <div className="allRight">
            <div className="littleContent flex">
              {/*<div className="iconwechats">*/}
              {/*  <div className="iconwechat">*/}
              {/*    <img src={footerData.wechat.img} className="iconwechat imgIcon cursor-pointer" alt="img"/>*/}
              {/*  </div>*/}
              {/*  <img src={footerData.wechat.QRcode} className="codes" alt="img"/>*/}
              {/*</div>*/}
              {footerData.IconList.map(({title, img, linkTo}) => {
                return <a href={linkTo} key={title} target="_blank" rel="noopener noreferrer">
                  <img src={img} className={`imgIcon cursor-pointer`} alt="img"/>
                </a>;
              })}
              <div className="copytoggle">
                <CopyToClipboard text={emails} onCopy={() => setCopied(true)}>
                  <img src={footerData.email.img} className={`imgIcon cursor-pointer`} alt="img"/>
                </CopyToClipboard>
                {copied && <span className="copyemails">
                  <span className="arrow">
                    Copied address
                  </span>
                </span>}
              </div>
            </div>
            <div className="reserved">{footerData.reserved}</div>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

export default Footer;