import styled from "styled-components";

export const BannerWrapper = styled.div`
    // height: 100vh;
`
export const Wrapper = styled.div`
    .titleAnimate {
        font-size: 58px;
        font-weight: bold;
        line-height: 70px;
        text-shadow: 0px 8px 12px rgba(0, 0, 0, 0.5);
        .txtRight {
            margin-right: 20px;
        }
        .titleTrusted {
            color: #FEE32B;
        }
        .titleBTC {
            color: #fff;
        }
        @media screen and (max-width: 550px) {
            font-size: 34px;
            line-height: 40px;
            .txtRight {
                margin-right: 10px;
            }
        }
    }
    .titleAnimate .element-from-blur {
        opacity: 0;
        will-change: opacity, filter, transform;
    }

    .titleAnimate .element-from-blur-animate {
        -webkit-animation: element-from-blur-animate 2s normal forwards;
        animation: element-from-blur-animate 2s normal forwards;
        -webkit-animation-delay: 1.5s;
        animation-delay: 1.5s;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        perspective: 1000;
        transform: translateZ(0);
    }
    @keyframes element-from-blur-animate {
        from {
            filter: blur(30px);
            opacity: 0;
        }
        to {
            filter: blur(0);
            opacity: 1;
        }
    }    
`

export const AnimateContent = styled.div`
    width: 100%;
    top: 20vh;
    padding: 30px 20px;
    z-index: 10;
    @media screen and (max-width: 550px) {
        top: 25vh;
    }
    .line {
        @media screen and (max-width: 550px) {
            margin: 20px 0;
        }
    }
    .txt {
        font-size: 28px;
        color: #FFFFFF;
        line-height: 34px;
        text-align: center;
        text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.56);
        @media screen and (min-width: 550px) {
            width: 580px;
            font-size: 20px;
            line-height: 32px;
        }
    }
    .whiteLine {
        top: 210px;
        width: 100%;
        height: 1px;
        opacity: 0.3;
        background: rgb(255, 255, 255);
        @media screen and (max-width: 550px) {
            top: 132px;
        }
    }
`