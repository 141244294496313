import gate from "../../assets/img/gate.png";
import mexc from "../../assets/img/mexc.png";
import aex from "../../assets/img/aex.png";
import kucoin from "../../assets/img/kucoin.png";
import hotbit from "../../assets/img/hotbit.png";
import soswap from "../../assets/img/soswap_logo.svg";
export const transferLinksData = {
  itemWidth: "254px",
  itemHeight: "100px",
  itemRadius: "10px",
  border: "1px solid rgba(0, 0, 0, 0.12)",
  backColor: "#FFFFFF",
  hoverBackColor: "rgba(254, 227, 43, 1)",
  shadowColor: "rgba(0, 0, 0, 0.15)",
  hoverShadowColor: "rgba(0, 0, 0, 0.34)",
  shadowHeight: "78px",
  children: [
    {
      imgButton: { imgSource: gate, width: "159px", height: "49px" },
      linkTo: "https://gate.io/",
    },
    {
      imgButton: { imgSource: mexc, width: "152px", height: "32px" },
      linkTo: "https://www.mexc.com/",
    },
    {
      imgButton: { imgSource: aex, width: "142px", height: "42px" },
      linkTo: "https://www.aex.com/",
    },
    {
      imgButton: { imgSource: kucoin, width: "171px", height: "40px" },
      linkTo: "https://www.kucoin.com",
    },
    {
      imgButton: { imgSource: hotbit, width: "167px", height: "42px" },
      linkTo: "https://www.hotbit.io/",
    },
    {
      imgButton: { imgSource: soswap, width: "167px", height: "42px" },
      linkTo: "https://soswap.finance/",
    }
  ],
};
