import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Block from "../../components/Block/Block";
import chainx_community from "../../assets/img/chainx_community.png";
import HoverLink from "../../lib/HoverLink";
import { joinUsData } from "../../utils/datas/joinUsData";
import { wayFindingData } from "../../utils/datas/wayFindingData";
import { Img } from "../../uiux/Img";
import useSetting from "../../utils/hooks/useSetting";

export interface CommunityPage {
  className?: string;
  browserType?: string;
}

const CommunityPage: React.FC<CommunityPage> = ({
  className,
}: CommunityPage) => {
  const { browserType } = useSetting();

  const [copied,setCopied] = useState(false)
  function CopiedEmail() {
    setCopied(true)
  }
  function RecoverEmail() {
    setCopied(false)
  }
  useEffect(() => {
    const Emails = document.querySelector(".Email");
    Emails?.addEventListener("click", CopiedEmail);
    Emails?.addEventListener("mouseout", RecoverEmail);
  }, []);

  return (
    <CommunityWrapper
      className={`${className} flex flex-col items-center`}
      browserType={browserType}
    >
      <Block
        margin={
          browserType === "CBOL" ? "124px 143px 98px 123px" : "268px 34px 44px"
        }
        img={{
          imgSource: chainx_community,
          width: browserType === "CBOL" ? "451px" : "346px",
          height: browserType === "CBOL" ? "298px" : "227px",
        }}
        title="ChainX Community"
        titleColor="#ffffff"
        titleSize={browserType === "CBOL" ? "56px" : "34px"}
        titleLineHeight={browserType === "CBOL" ? "70px" : "40px"}
        content="The ChainX community is a globally distributed home to developers, token holders, validators, and  the loyal fans of Bitcoin"
        contentSize={browserType === "CBOL" ? "20px" : "12px"}
        contentColor="rgba(255, 255, 255, 0.56)"
        background="#000"
        className="commuinty-banner"
        MBOLImgPosition="top:-268px;"
      />
      <div
        className={`links-wrapper flex justify-between items-center ${
          browserType === "MBOL" && "flex-col"
        }`}
      >
        {joinUsData.item.map((item) => {
          return (
            <HoverLink
              width={joinUsData.itemWidth}
              height={joinUsData.itemHeight}
              radius={joinUsData.itemRadius}
              linkTo={item.linkTo}
              background={item.background}
              textColor={joinUsData.textColor}
              hoverTextColor={joinUsData.hoverTextColor}
              shadowColor={joinUsData.shadowColor}
              hoverShadowColor={joinUsData.hoverShadowColor}
              hoverBackColor={joinUsData.hoverBackColor}
              hoverType={joinUsData.hoverType}
              hasArrow={item.hasArrow}
              textBottom={item.textBottom}
              className="circle-hover-link"
            >
              <div className="title">{item.title}</div>
              <div className="content">{item.content}</div>
            </HoverLink>
          );
        })}
      </div>
      <div className={`way-finding flex justify-center items-center flex-col`}>
        <div className="way-finding-title">{wayFindingData.title}</div>
        <div className="link-wrap flex justify-center items-center">
          {wayFindingData.item.map((item) => {
            return (
              <HoverLink
                width={wayFindingData.itemWidth}
                height={wayFindingData.itemHeight}
                radius={wayFindingData.itemRadius}
                border={wayFindingData.border}
                linkTo={item.linkTo}
                background="#ffffff"
                textColor={wayFindingData.textColor}
                shadowColor={wayFindingData.shadowColor}
                hoverShadowColor={wayFindingData.hoverShadowColor}
                hoverBackColor={wayFindingData.hoverBackColor}
                className={`way-finding-link ${item.title}`}
              >
                <span className="logo-text justify-center items-center">
                  {item.logoText}
                </span>
                <div className="name flex flex-col">
                  <Img imgSource={item.icon} width="32px" height="32px" />
                  <div className="title">{item.title}</div>
                </div>
                <div className="content">{copied ? item.actived || item.content : item.content}</div> 
              </HoverLink>
            );
          })}
        </div>
      </div>
    </CommunityWrapper>
  );
};

export default CommunityPage;

export const CommunityWrapper = styled.div<CommunityPage>`
  background: #f8f9fa;
  .banner {
    background: #000000;
    width: 100vw;
    .commuinty-banner {
      max-width: 1280px;
      .block-content {
        padding: 30px 0 0 !important;
      }
    }
  }

  .circle-hover-link {
    .title,
    .content {
      width: 191px;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      line-height: 29px;
      margin-bottom: 16px;
    }
    .content {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .way-finding {
    max-width: 1280px;
    .inner {
      justify-content: space-between;
      height: 100%;
    }

    .way-finding-title {
      margin: 64px 0 28px;
      font-size: 54px;
      font-weight: 600;
      text-align: center;
    }
    .link-wrap {
      max-width: 1280px !important;
      min-width: 1280px;
      margin-bottom: 88px;
      display: flex;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      position: relative;
      * {
        align-items: start;
      }
      a {
        margin: 18px;
        .way-finding-link {
          justify-content: space-between !important;
          padding: 24px;
          .name {
            .title {
              margin-top: 8px;
            }
          }
        }
      }
    }
    .logo-text {
      // width: 100px;
      padding: 0px 5px;
      height: 20px;
      background: #000000;
      border-radius: 4px;
      font-size: 11px;
      color: #ffffff;
    }
  }
  ${({ browserType }) => {
    switch (browserType) {
      case "MBOL":
        return `
        .way-finding-title{
          font-size: 40px !important;
        }
        .links-wrapper {
          min-height: 1267px;
          margin: 40px;
        }
        .link-wrap {
          max-width: 595px !important; 
          min-width: 595px !important; 
          width: 595px !important;
          margin-bottom: 32px !important;
          a{
            transform: scale(0.73);
            margin: -22px 0 !important;
            &:nth-child(2n-1){
              left:22px;
            }
            &:nth-child(2n){
              right:22px;
            }
          }
        }
        `;
        break;
      default:
        return `
        .links-wrapper {
          min-width: 1124px;
          margin: 60px;
        }`;
    }
  }}
`;
