import React from "react";
import styled from "styled-components";
import { Img } from "../uiux/Img";
import arrow_black from "../assets/img/arrow_black.png";
import arrow_white from "../assets/img/arrow_white.png";
import CopyToClipboard from "react-copy-to-clipboard";
export interface HoverLinkProps {
  width?: string;
  height?: string;
  radius?: string;
  border?: string;
  linkTo?: string;
  active?: any;
  background?: string;
  textColor?: string;
  hoverTextColor?: string;
  shadowColor?: string;
  hoverShadowColor?: string;
  hoverBackColor?: string;
  hoverType?: string;
  hasArrow?: boolean;
  textBottom?: string;
  className?: string;
  browserType?: string;
  children?: React.ReactNode;
}

const HoverLink: React.FC<HoverLinkProps> = ({
  width,
  height,
  radius,
  border,
  linkTo,
  background,
  textColor,
  hoverTextColor,
  shadowColor,
  hoverShadowColor,
  hoverBackColor,
  hoverType,
  hasArrow = false,
  textBottom,
  className,
  children,
}: HoverLinkProps) => {

  return (
    <a
      href={linkTo ? linkTo : "javascript: void(0);"}
      className={`relative ${ linkTo ? "cursor-pointer" : "cursor-auto"} `}
      style={{ display: "flex", width: width, height: height, zIndex: 1 }}
      target={linkTo ? '_blank' : ''}
    >
      <HoverLinkWrapper
        className={`${className} relative flex justify-center items-center flex-col`}
        width={width}
        height={height}
        radius={radius}
        background={background}
        textColor={textColor}
        hoverTextColor={hoverTextColor}
        shadowColor={shadowColor}
        hoverShadowColor={hoverShadowColor}
        hoverBackColor={hoverBackColor}
        hoverType={hoverType}
      >
        {border && <div className="border absolute" />}
        <div className={`inner flex flex-col  items-center`}>
          {children}
          <CopyToClipboard text="hi@chainx.org"><div className="round-wrap absolute flex justify-center items-center">
            <div className="hover-round absolute"></div>
          </div></CopyToClipboard>
          {hasArrow ? (
            <>
              <div className="row-wrap">
                <Img imgSource={arrow_white} className="unhover" />
                <Img imgSource={arrow_black} className="hover" />
              </div>
            </>
          ) : textBottom ? (
            <>
              <div className="text-bottom-wrap">{textBottom}</div>
            </>
          ) : null}
        </div>

        <div className="shadow absolute" />
      </HoverLinkWrapper>
    </a>
  );
};

export default HoverLink;

export const HoverLinkWrapper = styled.div<HoverLinkProps>`
  background: ${({ background = "transparent" }) => `${background}`};
  background-size: cover;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ radius }) => radius};
  .border {
    width: ${({ width }) => `calc(${width} + 2px)`};
    height: ${({ height }) => `calc(${height} + 2px)`};
    border-radius: ${({ radius }) => radius};
    left: -1px;
    top: -1px;
  }
  .round-wrap {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: ${({ radius }) => radius};
    overflow: hidden;
    left: 0;
    top: 0;
  }
  .hover-round {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: transparent;
  }
  .content {
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }
  .content,
  .title,
  .row-wrap,
  .text-bottom-wrap {
    z-index: 999;
  }
  ${({ hoverType, hoverBackColor, hoverTextColor }) => {
    let style = "";
    if (hoverType?.includes("content-show")) {
      style += `
      .content{
        color: transparent;
        top:20px;
        min-height:0;
        height:0;
      }
      &:hover,
      &:active {
        .content{
          color: ${hoverTextColor};
          top:0;
          min-height:50px;
          height:auto;
        }
      }
      `;
    }
    if (hoverType?.includes("circle")) {
      style += `
      &:hover,
      &:active {
        .hover-round{
          width: 400px;
          height: 400px;
          border-radius: 500px;
          background: ${hoverBackColor};
        }
      }
      `;
    } else {
      style += `
      .shadow {
        z-index: -1 !important;
      }
      &:hover,
      &:active {
        background: ${hoverBackColor};
      }
      `;
    }
    return style;
  }}
  transition: all 0.5s;
  * {
    transition: all 0.5s;
    color: ${({ textColor }) => `${textColor}`};
    text-align: center;
  }
  &:hover,
  &:active {
    * {
      color: ${({ hoverTextColor }) => `${hoverTextColor}`};
    }
  }
  .inner {
    z-index: 1;
  }
  .shadow {
    z-index: 0;
    bottom: -14px;
    height: 41px;
    width: 90%;
    background: ${({ shadowColor }) => `${shadowColor}`};
    border-radius: 10px;
    filter: blur(13px);
    justify-self: center;
    align-self: center;
  }
  .row-wrap,.text-bottom-wrap {
    position: absolute;
    bottom: 19px;
    // width: 15px;
    // height: 11px;
    * {
      width: 15px;
      height: 11px;
    }
    .unhover {
      display: block;
    }
    .hover {
      display: none;
    }
  }
  .text-bottom-wrap {
  }
  &:hover,
  &:active {
    .shadow {
      background: ${({ hoverShadowColor }) => `${hoverShadowColor}`};
    }
    .row-wrap {
      .unhover {
        display: none;
      }
      .hover {
        display: block;
      }
    }
  }
`;
