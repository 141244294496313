import React from "react";
import styled from "styled-components";
import Block from "../../components/Block/Block";
import chainx_wallet from "../../assets/img/chainx_wallet.png";
import polka_wallet from "../../assets/img/polka_wallet.png";
import coming_wallet from "../../assets/img/coming_wallet.png";
import google_play from "../../assets/img/google_play.png";
import android_download from "../../assets/img/android_download.png";
import ios_app_store from "../../assets/img/ios_app_store.png";
import useSetting from "../../utils/hooks/useSetting";

export interface WalletPage {
  className?: string;
  browserType?: string;
}

const WalletPage: React.FC<WalletPage> = ({ className }: WalletPage) => {
  const { browserType } = useSetting();

  return (
    <WalletWrapper
      className={`${className} flex flex-col`}
      browserType={browserType}
    >
      <Block
        margin={
          browserType === "CBOL" ? "216px -25px 198px 110px" : "310px 33px 58px"
        }
        img={{
          imgSource: chainx_wallet,
          width: browserType === "CBOL" ? "732px" : "calc(732px / 2)",
          height: browserType === "CBOL" ? "700px" : "calc(700px / 2)",
        }}
        title="Official Web Wallet of ChainX"
        titleColor="#ffffff"
        titleSize={browserType === "CBOL" ? "56px" : "34px"}
        titleLineHeight={browserType === "CBOL" ? "70px" : "40px"}
        content="The wallet can help you manage assets and participate in functions such as staking, proposals, and asset cross chains"
        contentSize={browserType === "CBOL" ? "20px" : "12px"}
        contentColor="rgba(255, 255, 255, 0.56)"
        button={{
          text: "ChainX Wallet",
          textColor: "rgba(1, 1, 1, 1)",
          hoverTextColor: "rgba(1, 1, 1, 1)",
          backColor: "rgba(254, 227, 43, 1)",
          hoverBackColor: "rgba(255, 255, 255, 1)",
          shadowColor: "rgba(254, 227, 43, 0.26)",
          hoverShadowColor: "rgba(255, 255, 255, 0.26)",
          radius: "8px",
          linkTo: "https://dapp.chainx.org/",
        }}
        background="#000"
        className="wallet-banner"
        MBOLImgPosition="top:-315px;"
      />
      <Block
        margin={
          browserType === "CBOL" ? "160px 147px 232px -25px" : "329px 43px 49px"
        }
        textRight={true}
        img={{
          imgSource: polka_wallet,
          width: browserType === "CBOL" ? "648px" : "calc(648px / 2)",
          height: browserType === "CBOL" ? "649px" : "calc(649px / 2)",
        }}
        title="Mobile Wallet For Polkadot ecosystem"
        titleSize={browserType === "CBOL" ? "40px" : "20px"}
        titleLineHeight={browserType === "CBOL" ? "48px" : "24px"}
        titleLine={{
          width: "100px",
          height: "1px",
          color: "rgba(0, 0, 0, .2)",
          paddingTB: browserType === "CBOL" ? "40px" : "15px",
        }}
        content="Polkawallet provide cross-chain asset one-stop management, convenient staking and governance, the private key is self-owned."
        contentSize={browserType === "CBOL" ? "20px" : "12px"}
        contentColor="rgba(1, 1, 1, 1)"
        button={{
          text: "Known More",
          textColor: "rgba(1, 1, 1, 1)",
          hoverTextColor: "rgba(255, 255, 255, 1)",
          backColor: "rgba(254, 227, 43, 1)",
          hoverBackColor: "rgba(1, 1, 1, 1)",
          shadowColor: "rgba(254, 227, 43, 0.2)",
          hoverShadowColor: "rgba(1, 1, 1, 0.2)",
          radius: "8px",
          linkTo: "https://polkawallet.io",
        }}
        background="#fff"
        className="polka-wallet"
        MBOLImgPosition="top:-300px !important;"
      />
      <Block
        margin={
          browserType === "CBOL" ? "131px 35px 220px 133px" : "318px 20px 62px"
        }
        contentPadding={browserType === "CBOL" ? "0" : "22px"}
        img={{
          imgSource: coming_wallet,
          width: browserType === "CBOL" ? "648px" : "calc(648px / 2)",
          height: browserType === "CBOL" ? "648px" : "calc(648px / 2)",
        }}
        title="ComingChat Wallet"
        titleSize={browserType === "CBOL" ? "40px" : "20px"}
        titleLineHeight={browserType === "CBOL" ? "48px" : "24px"}
        titleLine={{
          width: "100px",
          height: "1px",
          color: "rgba(0, 0, 0, .2)",
          paddingTB: browserType === "CBOL" ? "40px" : "15px",
        }}
        content="Use a secure non-custodial cryptocurrency wallet, support multi-currency transfers, and borderless cryptocurrency payments. The private key will be encrypted and stored in the user's device, and the user is 100% in control of his assets"
        contentSize={browserType === "CBOL" ? "20px" : "12px"}
        contentColor="rgba(1, 1, 1, 1)"
        buttonWrapper={[
          {
            totleImg: {
              imgSource: google_play,
              width: browserType === "CBOL" ? "135px" : "102px",
              height: browserType === "CBOL" ? "44px" : "33px",
            },
            shadowColor: "rgba(0, 0, 0, 0.2)",
            className: "buttonItem",
            linkTo: "https://www.coming.chat/downloads",
          },
          {
            totleImg: {
              imgSource: android_download,
              width: browserType === "CBOL" ? "136px" : "102px",
              height: browserType === "CBOL" ? "44px" : "33px",
            },
            shadowColor: "rgba(0, 0, 0, 0.2)",
            className: "buttonItem middle-button",
            linkTo: "https://www.coming.chat/downloads",
          },
          {
            totleImg: {
              imgSource: ios_app_store,
              width: browserType === "CBOL" ? "120px" : "90px",
              height: browserType === "CBOL" ? "44px" : "33px",
            },
            shadowColor: "rgba(0, 0, 0, 0.2)",
            className: "buttonItem",
            linkTo: "https://www.coming.chat/downloads",
          },
        ]}
        background="#fff"
        className="polka-wallet"
        MBOLImgPosition="top:-300px !important;"
      />
      <Block
        margin={browserType === "CBOL" ? "49px 0 34px" : "38px 26px 34px"}
        title="A trusted metaverse supporting decentralized identity, secured economic systems, and private socialized platforms for global users"
        titleSize={browserType === "CBOL" ? "24px" : "16px"}
        titleLineHeight="29px"
        titleLine={
          browserType === "CBOL"
            ? {
                width: "72px",
                height: "6px",
                color: "rgba(0, 0, 0, 1)",
                paddingTB: "32px",
              }
            : {
                width: "36px",
                height: "3px",
                color: "rgba(0, 0, 0, 1)",
                paddingTB: "22px",
              }
        }
        button={{
          text: "ComingChat",
          textColor: "rgba(255, 255, 255, 1)",
          hoverTextColor: "rgba(1, 1, 1, 1)",
          backColor: "rgba(1, 1, 1, 1)",
          hoverBackColor: "rgba(255, 255, 255, 1)",
          shadowColor: "rgba(0, 0, 0, 0.39)",
          hoverShadowColor: "rgba(0, 0, 0, 0.39)",
          radius: "8px",
          linkTo: "https://www.coming.chat",
        }}
        background="rgba(254, 227, 43, 1)"
        className="to-coming"
      />
    </WalletWrapper>
  );
};

export default WalletPage;

export const WalletWrapper = styled.div<WalletPage>`
  .polka-wallet .block-img {
    top: -91px;
  }
  .coming-wallet .block-img {
    top: -101px;
  }

  .to-coming {
    .title {
      width: 800px !important;
    }
  }
  ${({ browserType }) => {
    switch (browserType) {
      case "MBOL":
        return `
        .middle-button {
          margin: 0 20px;
        }
        
        `;
      default:
        return `
        .middle-button {
          margin: 0 40px;
        }
        `;
    }
  }}
`;
