import styled from "styled-components";

export const Wrappers = styled.div`
    flex-direction: column;
    @media screen and (max-width: 550px) {
        &.py-150, &.py-164, &.py-130, &.py-100 {
            padding: 30px 20px 60px;
        }
    }
`;

export const Title = styled.div`
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    color: #000000;
    text-align: center;
    @media screen and (min-width: 550px) {
        width: 49.625rem;
        font-size: 40px;
        line-height: 3rem;
        margin-bottom: 3.75rem;
        &.mob {
            display: none;
        }
    }
    @media screen and (max-width: 550px) {
        font-size: 1.5rem;
        &.web {
            display: none;
        }
    }
`;

export const Content = styled.div`
    width: 100%;
    justify-content: center;

    @media screen and (min-width: 550px) {
        &.ComingChat {
            align-items: end;
        }
        &.flex-row-reverse {
            flex-direction: row-reverse;
        }
    }
    @media screen and (max-width: 550px) {
        display: flex;
        flex-direction: column;
        .imgPic {
            padding: 0 20px;
        }
    }
`;
export const Introduce = styled.div`
    &.CustodialMode {
        padding-left: 9.6875rem;
    }
    &.CustodialModes {
        padding-right: 3rem;
    }
    &.NonCustodialMode {
        padding-left: 2.875rem;
    }
    &.ComingChat {
        padding-left: 8.125rem;
    }
    .mobTitle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 550px) {
            display: none;
        }
    }
    .line {
        width: 150px;
    }
    @media screen and (min-width: 550px) and (max-width: 1080px) {
        &.CustodialMode, &.ComingChat{
            padding-left: 2rem;
        }
    }
    @media screen and (max-width: 550px) {
        &.CustodialMode, &.CustodialModes, &.NonCustodialMode, &.ComingChat {
            padding: 40px 0 0 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
`
export const IntroduceTitle = styled.div`
    font-size: 40px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    line-height: 3rem;
    &.black {
        color: #000000;
    }
    &.yellow { 
        color: #FEE32B;
    }  
    @media screen and (max-width: 550px) {
        font-size: 1.5rem;
    }
`

export const ItemTitle = styled.div`
    font-size: 20px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    margin-bottom: 1.875rem;
    @media screen and (min-width: 1080px) {
        width: 23.875rem;
    }
    @media screen and (max-width: 550px) {
        text-align: center;
        font-size: 1rem;
    }
`

export const LittleContent = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 1.875rem;
    &.littleComingChat {
        margin-bottom: 4rem;
    }
    @media screen and (min-width: 1080px) {
        width: 30rem;
    }
    @media screen and (max-width: 550px) {
        text-align: center;
    }
`