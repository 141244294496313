import join_chainx from "../../assets/img/join_chainx.png";
import code from "../../assets/img/code.png";
import node from "../../assets/img/node.png";
import build_dapp from "../../assets/img/build_dapp.png";

export const joinUsData = {
  itemWidth: "254px",
  itemHeight: "295px",
  itemRadius: "10px",
  textColor: "rgba(255, 255, 255, 1)",
  hoverTextColor: "rgba(16, 16, 16, 1)",
  shadowColor: "rgba(0, 0, 0, .1)",
  hoverShadowColor: "rgba(105, 92, 2, .34)",
  hoverBackColor: "rgba(254, 227, 43, .7)",
  hoverType: "circle content-show",
  item: [
    {
      linkTo: "",
      background: `url(${join_chainx})`,
      title: "Become an ChainX Ambassador",
      content:
        "This Ambassador program is for anyone looking to join the core growth effort for Chainx",
      hasArrow: true,
    },
    {
      linkTo: "https://github.com/chainx-org/ChainX",
      background: `url(${code})`,
      title: "Code Contributions",
      content:
        "We welcome any engineer with ideas to contribute code to chainX, and we will give rich rewards to partners who have made outstanding contributions",
      hasArrow: true,
    },
    {
      linkTo: "https://chainx-org.github.io/documentation/docs/chainx/developers/devops/",
      background: `url(${node})`,
      title: "Run a node",
      content:
        "With Validator Nodes and Trustee Nodes up for grabs now, everyone stands a chance to apply. Let's support and safeguard the ChainX Network together!",
      hasArrow: true,
    },
    {
      linkTo: "",
      background: `url(${build_dapp})`,
      title: "Build Dapp",
      content:
        "Build a BTC-based application ecosystem by deploying new smart contract projects or moving existing ones onto ChainX.",
      textBottom: "Coming Soon",
    },
  ],
};
