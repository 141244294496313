import React from "react";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Autoplay } from "swiper";
import "swiper/components/effect-fade/effect-fade.scss";
import "swiper/swiper.min.css";
import Bitcoin from "../assets/video/bitcoin.webm";
import Bit from "../assets/video/bit.mp4";
import Metaverses from "../assets/video/metaverses.webm";
import Meta from "../assets/video/meta.mp4";
import PlaceHolder from "../assets/img/placeholder.webp"
import styled from "styled-components";
import TxtAnimate from "../components/Banner/txtAnimate";
import "video.js"
import "video.js/dist/video-js.css"
const Swiper = () => {
  SwiperCore.use([EffectFade, Autoplay]);
  return (
    <Wrapper>
    <SwiperComponent
      effect="fade"
      speed={1000}
      loop={true}
      autoplay={{
        // delay: 2000,
        delay: 10.4 * 1000,
      }} 
    >
      <SwiperSlide style={{ background: "transparent" }}>
        <video
          id="heroparticlesvideo-0"
          poster={PlaceHolder}
          controls={false}
          autoPlay
          loop
          playsInline
          webkit-playsinline
          preload="none"
          muted
          height="100%"
          width="100%"
          x5-video-player-type="h5"
          className="videoMob"
        >
          <source src={Bitcoin} type="video/webm" />
          <source src={Bit} type="video/mp4" />
          Your browser does not support video on HTML.
        </video>
      </SwiperSlide>
      <SwiperSlide style={{ background: "black" }}>
        <video
          id="my-player"
          poster={PlaceHolder}
          controls={false}
          autoPlay
          loop
          playsInline
          preload="auto"
          muted
          height="100%"
          width="100%"
          data-setup='{}'
          className="video-js videoMob"
        >
          <source src={Metaverses} type="video/webm" />
          <source src={Meta} type="video/mp4" />
          Your browser does not support video on HTML.
        </video>
      </SwiperSlide>
    </SwiperComponent>
    <TxtAnimate />
    <div className="mark absolute"></div>
    </Wrapper>
  );
};

export default Swiper;

export const Wrapper = styled.div`
  position: relative;
  // height: 100vh;
  .swiper-container {
    height: 100vh;
  }
  .mark {
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    background: rgba(0,0,0,0.3);
  }
  .videoMob {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`