import styled from "styled-components";

export const Wrappers = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(254, 227, 43, 1);
  padding: 0 2rem;
  color: #000000;
  z-index: 100;
  @media screen and (min-width: 550px) {
    position: absolute;
    bottom: 0;
  }
  @media screen and (min-width: 550px) and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }
  @media screen and (max-width: 550px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 0;
    .itemList {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Title = styled.div`
  font-size: 2rem;
  font-family: Helvetica-Bold, Helvetica;
  font-weight: bold;
  line-height: 2.375rem;
  @media screen and (min-width: 600px) {
    width: 26rem;
    margin-right: 2.5rem;
  }
  @media screen and (max-width: 600px) {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 30px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    background: rgba(0, 0, 0, 1);
    color: #fff;
    .mode .imgBg {
      background: #fff;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 1080px) {
    margin-left: 1.75rem;
  }
  @media screen and (max-width: 600px) {
    margin: 0 0 2.3rem;
  }
  .imgBg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    background: rgba(1,1,1,.05);
    border-radius: 50%;
    margin-right: 1.5rem;
    @media screen and (max-width: 600px) {
      width: 48px;
      height: 48px;
    }
  }
  .content {
    font-size: 18px;
    font-family: Helvetica-Bold, Helvetica;
    font-weight: bold;
    line-height: 1.5rem;
    p {
      margin-bottom: 4px;
    }
  }
`;

export const ButtonWrapper = styled.a`
  text-decoration: none;
  cursor: pointer;
  @media screen and (min-width: 550px) {
    padding: 2.5rem 2.2rem;
  }
  .more {
    .Arrow_black {
      position: absolute;
      right: 0;
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
    }
  }
  span {
    font-size: 14px;
    color: #fff;
    text-align: center;
    margin-right: 20px;
  }
  &:hover span {
    color: #fff;
    .Arrow_black {
      border: solid white;
      border-width: 0 2px 2px 0;
    }
  }
  &:hover .more {
    width: 30px;
  }
  .more:before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform: translateY(-44%);
    content: "";
  }
  .more {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    color: #000;;
    vertical-align: middle;
    background: transparent;
    cursor: pointer;
    transition: all .45s cubic-bezier(.165,.84,.44,1);
  }
`
