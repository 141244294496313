import React from "react";
import styled from "styled-components";
import useSetting from "../utils/hooks/useSetting";
import { Img, ImgProps } from "./Img";

/** button */
export interface ButtonProps {
  className?: string;
  width?: string;
  height?: string;
  radius?: string;
  border?: string;
  totleImg?: ImgProps;
  imgButton?: ImgProps;
  onClick?: React.MouseEventHandler<Element>;
  text?: string;
  logo?: ImgProps;
  linkTo?: string;
  textColor?: string;
  hoverTextColor?: string;
  backColor?: string;
  hoverBackColor?: string;
  shadowColor?: string;
  shadowHeight?: string;
  hoverShadowColor?: string;
  browserType?: string;
}

export const Button = ({
  className,
  width,
  height,
  radius,
  border,
  totleImg,
  imgButton,
  onClick,
  text,
  logo,
  linkTo,
  textColor,
  hoverTextColor,
  hoverBackColor,
  backColor,
  shadowColor,
  shadowHeight,
  hoverShadowColor,
}: ButtonProps) => {
  const { browserType } = useSetting();
  return (
    <ButtonWrap
      className={`${className} relative flex justify-center items-center`}
      textColor={textColor}
      hoverTextColor={hoverTextColor}
      hoverBackColor={hoverBackColor}
      backColor={backColor}
      shadowColor={shadowColor}
      hoverShadowColor={hoverShadowColor}
      onClick={onClick}
      width={width}
      height={height}
      radius={radius}
      border={border}
      browserType={browserType}
    >
      {totleImg ? (
        <a href={linkTo ? linkTo : "javascript:void(0);"}  target="blank">
          <Img {...totleImg} />
        </a>
      ) : imgButton ? (
        <section className="color-1">
          <p>
            <a href={linkTo ? linkTo : "javascript:void(0);"}  target="blank">
              <Img {...imgButton} className={`hover-btn btn btn-1 btn-1c`} />
            </a>
          </p>
        </section>
      ) : (
        <section className="color-1">
          <p>
            <a href={linkTo ? linkTo : "javascript:void(0);"}  target="blank">
              <button className={`hover-btn btn btn-1 btn-1c`}>
                {logo && <Img {...logo} />}
                <span>{text}</span>
              </button>
            </a>
          </p>
        </section>
      )}
      <div className="shadow absolute" />
    </ButtonWrap>
  );
};

export const ButtonWrap = styled.div<ButtonProps>`
  display: inline-flex;
  width: ${({ width }) => `calc(${width} + 2px)`};
  height: ${({ height = 1 }) => `calc(${height} + 2px)`};
  img {
    z-index: 1;
  }
  section {
    width: ${({ width }) => `${width}`};
    height: ${({ height = 1 }) => `${height}`};
    border-radius: ${({ radius }) => radius};
    border: ${({ border }) => border};
    z-index: 1;
    margin: 0 auto;
    text-align: center;
    color: ${({ textColor }) => `${textColor}`};
    overflow: hidden;
  }
  section p {
    width: ${({ width }) => `${width}`};
    height: ${({ height = 1 }) => `${height}`};
    position: relative;
    margin: 0;
    z-index: 1;
    font-size: 14px;
  }
  .shadow {
    z-index: 0;
    bottom: -14px;
    width: 90%;
    background: ${({ shadowColor }) => `${shadowColor}`};
    height: ${({ shadowHeight = "41px" }) => `${shadowHeight}`};
    border-radius: 10px;
    filter: blur(13px);
  }
  &:hover,
  &:active {
    .shadow {
      background: ${({ hoverShadowColor }) => `${hoverShadowColor}`};
    }
  }
  .color-1 {
    background: ${({ backColor }) => backColor};
  }

  /* General button style (reset) */
  .btn {
    width: ${({ width }) => `${width}`};
    height: ${({ height = 1 }) => `${height}`};
    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    border: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: 600;
    color: inherit;
    cursor: pointer;
    padding: 12px 44px;
    letter-spacing: 1px;
    outline: none;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;

    .icon-hover {
      display: none;
    }
    .icon {
      display: inline-flex;
    }
  }

  .btn:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  .btn-1 {
    overflow: hidden;
  }

  .btn-1c:after {
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background: ${({ hoverBackColor }) => `${hoverBackColor}`};
  }
  .btn-1c:hover,
  .btn-1c:active {
    .icon-hover {
      display: inline-flex;
    }
    .icon {
      display: none;
    }
  }
  .hover-btn:hover,
  .hover-btn:active {
    color: ${({ hoverTextColor }) => `${hoverTextColor}`};
  }
  .btn-1c:hover:after,
  .btn-1c:active:after {
    width: 100%;
  }
  ${({ browserType }) => {
    switch (browserType) {
      case "MBOL":
        return `
        
        `;
      default:
        return ``;
    }
  }}
`;
