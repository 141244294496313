import styled from "styled-components";

export const HomeWrapper = styled.div`
  .smartContract {
    padding: 110px 30px 50px;
    @media screen and (max-width: 550px) {
      padding: 30px 20px 20px;
      .inner {
        width: 100% !important;
      }
      .text, .title {
        width: 100% !important;
        font-size: 24px !important;
        line-height: 32px !important;
        .block-content {
          font-size: 16px !important;
        }
      }
    }
    .text {
      text-align: center;
    }
    @media screen and (min-width: 1080px) {
      padding: 114px 30px 50px;
      .text {
        width: 57.82rem !important;
        .block-content {
          line-height: 28px !important;
        }
      }
      .title {
        width: 41.25rem !important;
      }
    }
  }
  .dapps {
    background-size: 100%;
    padding: 80px 30px 60px;
    @media screen and (max-width: 550px) {
      padding: 60px 30px 80px;
      .text, .title {
        width: 100% !important;
        line-height: 40px;
        font-size: 24px !important;
        padding: 0 26px;
      }
    }
    @media screen and (min-width: 1080px) {
      padding: 146px 30px 114px;
      .title {
        width: 60rem !important;
      }
    }
    @media screen and (max-width: 1280px) {
      background-size: auto;
    }
  }
  .cardList {
    background: #FEE32B;
    padding: 140px 0;
    @media screen and (max-width: 550px) {
      padding: 60px 30px;
    }
    .mob {
      flex-wrap: wrap;
      a {
        margin-bottom: 20px;
      }
      @media screen and (max-width: 550px) {
        flex-direction: column;
      }
    }
    .cardListLink {
      margin: 0 12px;
      padding: 24px;
      .inner {
        height: 100%;
        text-align: start;
        align-items: baseline;
        justify-content: space-between;
        .innerCard {
          align-items: baseline;
          .title, .content {
            text-align: start;
          }
          .title {
            font-size: 22px;
            font-weight: bold;
            line-height: 29px;
            margin-bottom: 16px;
          }
          .content {
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
  }
`;