import * as React from "react";
import { Wrappers, Title, Item, ButtonWrapper } from "./style"
import { Spring, animated } from 'react-spring'
import Custodial from "./Custodial.svg";
import NonCustodial from "./Non-custodial.svg";

const ModeTitles = () => {
  return (
    <Wrappers className={`flex`}>
      <Spring 
        config={{duration: 500}}
        to={{ opacity: 1, y: 0 }} from={{ opacity: 0, y: 50}}>
        {styles => (
          <animated.div style={styles}>
            <Title>Build the trust of Bitcoin on Layer2 Networks</Title>
        </animated.div>
      )}
      </Spring>
      <div className='itemList flex'>
        <Spring 
          delay={130}
          config={{duration: 500}}
          to={{ opacity: 1, y: 0 }} from={{ opacity: 0, y: 50}}>
          {styles => (
          <animated.div style={styles}>
            <Item className='itemCustodial cursor-pointer'>
              <ButtonWrapper href="#CustodialMode" className="flex items-center mode">
                <div className='imgBg'>
                  <img src={Custodial} className="Custodial" alt="Custodial" />
                </div>
                <div className='content'>
                  <p>Custodial mode</p>
                  <span className="more">
                    <i className='Arrow_black'></i>
                  </span>
                </div>
              </ButtonWrapper>
            </Item>
          </animated.div>
        )}
        </Spring>
        <Spring 
          delay={260}
          config={{duration: 500}}
          to={{ opacity: 1, y: 0 }} from={{ opacity: 0, y: 50}}>
          {styles => (
          <animated.div style={styles}>
            <Item className="cursor-pointer">
              <ButtonWrapper href="#NonCustodialMode" className="flex items-center mode">
                <div className='imgBg'>
                  <img src={NonCustodial} className="NonCustodial" alt="NonCustodial" />
                </div>
                <div className='content'>
                  <p>Non-custodial mode</p>
                  <span className="more">
                    <i className='Arrow_black'></i>
                  </span>
                </div>
              </ButtonWrapper>
            </Item>
          </animated.div>
        )}
        </Spring>
      </div> 
    </Wrappers>
  )
};


export default ModeTitles
