import React from "react";
import styled from "styled-components";

/** line */
export interface LineProps {
  className?: string;
  color?: string;
  width?: string;
  height?: string;
  paddingLR?: string;
  paddingTB?: string;
  opacity?: string;
}
const LineWrapper = styled.div<LineProps>`
  display: inline-flex;
  width: ${({ width }) => `${width}`};
  height: ${({ height = 1 }) => `${height}`};
  margin: ${({ paddingTB = 0, paddingLR = 0 }) => `${paddingTB} ${paddingLR}`};
  background: ${({ color = "#ffffff" }) => `${color}`};
  opacity: ${({ opacity }) => `${opacity}`}
`;

export const Line = ({
  className,
  width,
  height,
  color,
  paddingLR,
  paddingTB,
  opacity,
}: LineProps) => {
  return (
    <LineWrapper
      width={width}
      height={height}
      color={color}
      className={className}
      paddingLR={paddingLR}
      paddingTB={paddingTB}
      opacity={opacity}
    />
  );
};
