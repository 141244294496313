import React, { useEffect } from "react";
import { Spring, animated } from 'react-spring'
import { Wrapper, AnimateContent } from "./style";
import { Line } from "../../uiux/Line";

const TxtAnimate = () => {
  useEffect(()=>{   
    const Animates = document.getElementsByTagName('span');
    for(let i = 0; i < Animates.length; i++) {
        Animates[i].classList.add('element-from-blur-animate')
    }
  },[])
  return (
        <AnimateContent className="absolute text">
            <div className="flex flex-col justify-center items-center">
                <Wrapper >
                    <div className="titleAnimate">
                        <div className="titleTrusted">
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.9271s"}}>T</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.17229s"}}>r</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "0.824283s"}}>u</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.32037s"}}>s</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.39679s"}}>t</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "0.67457s"}}>e</span>
                            <span className="notranslate txtRight  element-from-blur " style={{animationDuration: "1.58248s"}}>d</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "0.81832s"}}>a</span> 
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.7271s"}}>n</span>
                            <span className="notranslate txtRight element-from-blur " style={{animationDuration: "1.17229s"}}>d</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "0.824283s"}}>S</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.22037s"}}>c</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.59679s"}}>a</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "0.67457s"}}>l</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.58248s"}}>a</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.591832s"}}>b</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.591832s"}}>l</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.591832s"}}>e</span>
                        </div>
                        <div className="titleBTC">
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.7271s"}}>B</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.17229s"}}>T</span>
                            <span className="notranslate txtRight element-from-blur " style={{animationDuration: "0.624283s"}}>C</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.22037s"}}>L</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.59679s"}}>a</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "0.67457s"}}>y</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.58248s"}}>e</span>
                            <span className="notranslate txtRight element-from-blur " style={{animationDuration: "0.591832s"}}>r</span> 
                            <span className="notranslate txtRight element-from-blur " style={{animationDuration: "1.7271s"}}>2</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.17229s"}}>N</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "0.624283s"}}>e</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.22037s"}}>t</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.59679s"}}>w</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "0.67457s"}}>o</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "1.58248s"}}>r</span>
                            <span className="notranslate element-from-blur " style={{animationDuration: "0.591832s"}}>k</span>
                        </div>
                    </div>
                </Wrapper>
                <div className='whiteLine absolute'></div>
                <Spring 
                    delay={2000}
                    config={{duration: 1000}}
                    to={{ opacity: 1, x: 0 }} from={{ opacity: 0, x: -100}}>
                        {styles => (
                            <animated.div style={styles}>
                        <div className="flex flex-col justify-center items-center">
                            <Line className="line" width="96px" height="4px" color='#FEE32B' paddingTB="38px" opacity="1" />
                            <div className='txt'>ChainX is the community-run layer2 network of bitcoin, and your portal into the world of Bitcoin ecosysterm.</div>
                        </div>
                    </animated.div>
                )}
                </Spring>
            </div>
        </AnimateContent>
  );
};

export default TxtAnimate;
