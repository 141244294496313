import React from "react";
import styled from "styled-components";

/** img */
export interface ImgProps {
  imgSource?: string;
  className?: string;
  width?: string;
  height?: string;
  paddingTB?: string;
  paddingLR?: string;
  // onClick?: React.MouseEventHandler<Element>;
  onClick?: React.MouseEventHandler<Element>;
}

const ImgWrapper = styled.div<ImgProps>`
  display: inline-flex;
  margin: ${({ paddingTB = 0, paddingLR = 0 }) => `${paddingTB} ${paddingLR}`};
  background: ${({ color = "transparent" }) => `${color}`};
  width: ${({ width }) => `${width}`};
  height: ${({ height }) => `${height}`};
  img {
    width: ${({ width }) => `${width}`};
    height: ${({ height }) => `${height}`};
  }
`;
export const Img = ({
  className,
  onClick,
  imgSource,
  width,
  height,
  paddingTB,
  paddingLR,
}: ImgProps) => {
  return (
    <ImgWrapper
      width={width}
      height={height}
      paddingTB={paddingTB}
      paddingLR={paddingLR}
      className={className}
    >
      <img src={imgSource} alt="" onClick={onClick} className="absolute" />
    </ImgWrapper>
  );
};
