import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HeaderWrapper, MenuPopUp, MobileDataBox } from './style';
import { Img } from '../../uiux/Img';
import { headerData } from '../../utils/datas/headerData';
import HeaderMobile from '../HeaderMobile';
import { arrowSvg, titleArrow } from './svg';

const Header = () => {

  const [pathNames, setPathNames] = useState(window.location.pathname);

  interface chainList {
    itemTitle: string;
    content?: string;
    itemChildren: listItem[];
  }

  interface listItem {
    name: string;
    Icon: string;
    href: string;
    content?: string;
  }

  const headerTitle = (ListItem: any, title: string) => {
    return <div id="menu" className="menu">
      {/*<img src={tangleIcon} alt="" style={{position:'absolute',top:'-6px', left:`${title==='Tools'?'52.5%':'53.5%'}`}}/>*/}
      <MenuPopUp isTool={title === 'Tools'}>
        {ListItem?.map((menuList: any) => {
          return <div className={'menuList'} key={menuList.itemTitle}>
            <div className={'title'}>{menuList.itemTitle}</div>
            <div className={'content'}>{menuList.content}</div>
            {menuList.itemChildren?.map((detail: any) => {
              return (
                <>
                  {detail.href && <a className={'childItem'} href={detail.href} target="_blank" key={detail.name}>
                    <img src={detail.Icon} alt=""/>
                    <div className={'container'}>
                      <div className={'name'}>
                        <span>{detail.name}</span>
                        {arrowSvg}
                      </div>
                      {detail?.content && <div className={'content'}>{detail.content}</div>}
                    </div>
                  </a>}
                  {detail.linkTo && <Link className={'childItem'} to={detail.linkTo} key={detail.name}>
                    <img src={detail.Icon} alt=""/>
                    <div className={'container'}>
                      <div className={'name'}>
                        <span>{detail.name}</span>
                        {arrowSvg}
                      </div>
                      {detail?.content && <div className={'content'}>{detail.content}</div>}
                    </div>
                  </Link>}
                </>
              );
            })}
          </div>;
        })}
      </MenuPopUp>
    </div>;
  };

  const headerDoc = (ListItem: any) => {
    return <div id="menuDoc" className="menu doc">
      {/*<img src={tangleIcon} alt="" style={{position:'absolute',transform: 'translate(585%,-40%)'}} />*/}
      <MenuPopUp isCol={true}>
        {ListItem.map((detail: any) => {
          return (
            <div className={'menuList'}>
              {detail.href && <a className={'childItem'} href={detail.href} target="_blank" key={detail.name}>
                <img src={detail.Icon} alt=""/>
                <div className={'container'}>
                  <div className={'name'}>
                    <span>{detail.name}</span>
                    {arrowSvg}
                  </div>
                  {detail?.content && <div className={'content'}>{detail.content}</div>}
                </div>
              </a>}
            </div>);
        })}
      </MenuPopUp>
    </div>;
  };


  return (
    <div className={'relative'} style={{height: '56px'}}>
      <HeaderWrapper className={`flex items-center ${pathNames === '/' ? 'homepage' : 'otherpage'}`}>
        <div className="flex items-center justify-between containers">
          <Link to="/" className="flex">
            <Img {...headerData.imgPic} className="ChainX_logo flex items-center "/>
          </Link>
          <div className="list flex web">
            {headerData.navRouter.map((item) => {
              return (
                <div
                  key={item.title}
                  className={`item flex items-center ${
                    item.children ? 'hoverable hoverable-menu' : ''
                  }`}
                >
                  {item.children ? (
                    <>
                      <div className="title-item flex justify-center items-center whitespace-nowrap">
                        {item.title}
                        {titleArrow}
                      </div>
                    </>
                  ) : (
                    <Link to={item?.path}>
                      <div className="title-item flex justify-center items-center">
                        {item.title}
                      </div>
                    </Link>
                  )}
                  {item.title === 'Doc' && headerDoc(item.children)}
                  {item.title !== 'Doc' && item.children && headerTitle(item.children, item.title)}
                </div>
              );
            })}
          </div>
        </div>
      </HeaderWrapper>
      <MobileDataBox className="mob"><HeaderMobile/></MobileDataBox>
    </div>

  );
};

export default Header;
