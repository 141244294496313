import LowestCost from "../../assets/img/LowestCost.svg"
import PlugIns from "../../assets/img/Plug-ins.svg";
import Solidity from "../../assets/img/Solidity.svg";
import RPC from "../../assets/img/RPC.svg"

export const homeCardData = {
  itemWidth: "254px",
  itemHeight: "295px",
  itemRadius: "10px",
  background: "#fff",
  textColor: "rgba(63, 63, 63, 1)",
  shadowColor: "rgba(0, 0, 0, .1)",
  hoverShadowColor: "rgba(105, 92, 2, .34)",
  hoverTextColor: "#fff",
  hoverBackColor: "rgba(0, 0, 0, 1)",
  item: [
    {
      icon: LowestCost,
      title: "Lowest Cost Migration",
      content: "Deploy on ChainX with a simple change to your smart contract. ",
    },
    {
      icon: PlugIns,
      title: "Familiar Tools and Plug-ins",
      content: "Ethereum-based tools such as Truffle，MetaMask, Waffle, Remix and Hardhat  can be used.",
    },
    {
      icon: Solidity,
      title: "Solidity Smart Contracts",
      content: "Support solidity and any development language that compiles to EVM bytecode.",
    },
    {
      icon: RPC,
      title: "Web3 RPC and H160 Accounts",
      content: "Compatible with Web3 protocol of Ethereum. Interact with ChainX using your existing Ethereum accounts and keys.",
    },
  ],
};
