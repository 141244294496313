import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
// import useSetting from "./utils/hooks/useSetting";
import HomePage from "./pages/home";
import WalletPage from "./pages/wallet";
import CommunityPage from "./pages/community";
import TransferPage from "./pages/transfer";
import Header from "./components/Header";
import Footer from "./components/Footer";
const I18nWrap = styled.div``;
const App: React.FC = () => {
  const { i18n } = useTranslation();
  const language = i18n.language;
  // const { browserType } = useSetting();
  return (
    <I18nWrap {...{ language }}>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/transfer">
            <TransferPage />
          </Route>
          <Route exact path="/wallet">
            <WalletPage />
          </Route>
          <Route exact path="/community">
            <CommunityPage />
          </Route>
          <Route exact path="/">
            <HomePage />
          </Route>
        </Switch>
        <Footer />
      </BrowserRouter>
    </I18nWrap>
  );
};

export default App;
