import React, { useState } from 'react';
import { Props, slide as Menu } from 'react-burger-menu';
import more from '../../assets/mobile/icon_more.svg';
import close from '../../assets/mobile/icon_close.svg';
import styled from 'styled-components';
import { headerData } from '../../utils/datas/headerData';
import { MenuPopUp } from '../Header/style';
import { Link } from 'react-router-dom';
import IconChainxBalck from '../../assets/img/ChainX_Logo_Black.svg';


const LogoDiv = styled.div`
  display: block;
  padding: 5.3vw;
  margin-bottom: 8.5vw;
`;

const MenuItem = styled.div<{ hasChild?: boolean }>`
  background-color: white !important;

  #container {
    float: left;
    width: 100%;
  }

  .menu {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: left;
    font-size: 4.8vw;
    font-weight: 400;
    color: #131415;
    padding: 3.8vw 5.3vw;
    outline: none;
    transition: .25s;

    :hover {
      color: #F6C84A;
    }
  }

  .menu:after {
    content: "";
    font-size: 1em;
    float: right;
    color: #c9c9c9;
  }

  .menu.active:after {
    content: "";
    font-size: 1em;
    float: right;
    color: #c9c9c9;
  }


  .text {
    padding: 0 11.7vw;
    color: #515151;
    transition: .3s ease-out;
    opacity: 0;
    max-height: 0;
    overflow: scroll;
  }

  .text.show {
    display: block;
    opacity: 1;
    max-height: 150vw;
  }

`;
const DetailItem = styled.div`
  .title {
    font-size: 4.2vw;
    font-weight: 500;
    color: #131415;
    padding: 3.8vw 0;
  }

  > a {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2.9vw 0;

    > img {
      width: 8.3vw;
      margin-right: 2.1vw;
    }

    > span {
      font-size: 4.2vw;
      font-weight: 500;
      color: #131415;
    }
  }
`;

export default (
  props: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<Menu> &
    Readonly<Props> &
    Readonly<{ children?: React.ReactNode }>
) => {
  const headerTitle = (ListItem: any, title: string) => {
    return <div>
      {ListItem?.map((menuList: any) => {
        return <DetailItem key={menuList.itemTitle}>
          <div className={'title'}>{menuList.itemTitle}</div>
          {menuList.itemChildren?.map((detail: any) => {
            return (
              <>
                {detail.href && <a className={'childItem'} href={detail.href} target="_blank" onClick={closeBmMenu}>
                  <img src={detail.Icon} alt=""/>
                  <span>{detail.name}</span>
                </a>}
                {detail.linkTo && <Link className={'childItem'} to={detail.linkTo} onClick={closeBmMenu}>
                  <img src={detail.Icon} alt=""/>
                  <span>{detail.name}</span>
                </Link>}
              </>
            );
          })}
        </DetailItem>;
      })}
    </div>;
  };
  const headerDoc = (ListItem: any) => {
    return <div>
      <MenuPopUp isCol={true}>
        {ListItem.map((detail: any) => {
          return (
            <DetailItem key={ListItem.itemTitle} onClick={closeBmMenu}>
              {detail.href && <a className={'childItem'} href={detail.href} target="_blank">
                <img src={detail.Icon} alt=""/>
                <span>{detail.name}</span>
              </a>}
              {detail.linkTo && <Link className={'childItem'} to={detail.linkTo}>
                <img src={detail.Icon} alt=""/>
                <span>{detail.name}</span>
              </Link>}
            </DetailItem>);
        })}
      </MenuPopUp>
    </div>;
  };

  const isMenuOpen = function (state: { isOpen: any; }) {
    setOpenMenu(state.isOpen);
  };

  const closeBmMenu = () => {
    setOpenMenu(false);
  };

  const [openMenu, setOpenMenu] = useState(false);

  return (
    <Menu
      right
      {...props}
      isOpen={openMenu}
      customBurgerIcon={<img src={more}/>}
      customCrossIcon={<img src={close}/>}
      onStateChange={isMenuOpen}
    >
      <LogoDiv onClick={closeBmMenu}>
        <Link to={'/'}>
          <img src={IconChainxBalck} alt="" style={{width: '27vw'}}/>
        </Link>
      </LogoDiv>

      {headerData.navRouter.map((item) => {
        return <MenuItem key={item.title} hasChild={true}>
          <div id="container">
            {(item.title === 'Community' || item.title === 'Token') ?
              <Link className={'childItem'} to={item.path ? item.path : '/'} onClick={closeBmMenu}>
                <div className="menu">
                  {item.title}
                </div>
              </Link>
              : <div className="menu">{item.title}</div>
            }
            <div className="text">
              {item.title === 'Doc' && headerDoc(item.children)}
              {item.title !== 'Doc' && item.children && headerTitle(item.children, item.title)}
            </div>
          </div>
        </MenuItem>;
      })}
    </Menu>
  );
};

