import React from "react";
import styled from "styled-components";
import { Button, ButtonProps } from "../../uiux/Button";
import { Img, ImgProps } from "../../uiux/Img";
import { Line, LineProps } from "../../uiux/Line";
import useSetting from "../../utils/hooks/useSetting";
export interface BlockProps {
  margin?: string;
  img?: ImgProps;
  title?: string;
  titleColor?: string;
  titleSize?: string;
  titleLineHeight?: string;
  titleImg?: ImgProps;
  titleLine?: LineProps;
  content?: string | React.ReactNode;
  contentPadding?: string;
  contentColor?: string;
  contentSize?: string;
  background?: string;
  textRight?: boolean;
  button?: ButtonProps;
  buttonWrapper?: ButtonProps[];
  className?: string;
  browserType?: string;
  MBOLImgPosition?: string;
}

const Block: React.FC<BlockProps> = ({
  margin,
  img,
  title,
  titleColor,
  titleSize,
  titleLineHeight,
  titleImg,
  titleLine,
  content,
  contentPadding,
  contentColor,
  contentSize,
  background,
  textRight,
  button,
  buttonWrapper,
  className,
  MBOLImgPosition,
}: BlockProps) => {
  const { browserType } = useSetting();
  // @ts-ignore
  return (
    <BlockWrapper
      className={`${className} flex justify-center items-center `}
      titleColor={titleColor}
      titleSize={titleSize}
      titleLineHeight={titleLineHeight}
      textRight={textRight}
      background={background}
      contentColor={contentColor}
      contentPadding={contentPadding}
      contentSize={contentSize}
      margin={margin}
      titleLine={titleLine}
      browserType={browserType}
      MBOLImgPosition={MBOLImgPosition}
    >
      <div
        className={`inner relative flex flex-row ${
          img ? "justify-between" : "justify-center"
        } items-center`}
      >
        <div
          className={`margin relative flex flex-row ${
            img ? "justify-between" : "justify-center"
          } items-center`}
        >
          <div
            className={`text ${
              img ? "" : "flex flex-col justify-center items-center"
            }`}
          >
            {title && (
              <div className={`title ${img ? "" : "text-center"}`}>{title}</div>
            )}
            {titleImg && <Img {...titleImg} className="title-img" />}
            {titleLine && <Line {...titleLine} />}
            {content && <div className="block-content">{content}</div>}
            {button && <Button {...button} />}
            {buttonWrapper &&
              buttonWrapper.map((buttomItem: ButtonProps) => {
                return <Button {...buttomItem} />;
              })}
          </div>
          <Img {...img} className={`block-img absolute`} />
        </div>
      </div>
    </BlockWrapper>
  );
};

export default Block;

export const BlockWrapper = styled.div<BlockProps>`
  background: ${({ background = "transparent" }) => `${background}`};
  width: 100%;
  .inner {
    width: 1280px;
    .margin {
      width: inherit;
      flex-direction: ${({ textRight }) => (textRight ? `row-reverse` : `row`)};
      margin: ${({ margin }) => `${margin}`};
      .text {
        width: 510px;
        .title {
          color: ${({ titleColor = "#010101" }) => `${titleColor}`};
          font-size: ${({ titleSize = "40px" }) => `${titleSize}`};
          width: 471px;
          font-weight: bold;
          line-height: ${({ titleLineHeight = "48px" }) =>
            `${titleLineHeight}`};
        }
        .block-content {
          color: ${({ contentColor = "#ffffff" }) => `${contentColor}`};
          font-size: ${({ contentSize = "14px" }) => `${contentSize}`};
          * {
            color: ${({ contentColor = "#ffffff" }) => `${contentColor}`};
            font-size: ${({ contentSize = "14px" }) => `${contentSize}`};
            text-align: center;
          }
          padding: 40px ${({ contentPadding = 0 }) => contentPadding} 65px;
          font-weight: 400;
          letter-spacing: 0.7px;
          line-height: 24px;
          ${({ titleLine }) =>
            `${titleLine ? "padding-top: 0 !important;" : ""}`};
        }
      }
      .block-img {
        ${({ textRight, browserType }) =>
          browserType === "CBOL" && (textRight ? `left:0;` : `right: 0;`)}
      }
    }
  }
  ${({ browserType, MBOLImgPosition, contentPadding = 0 }) => {
    switch (browserType) {
      case "MBOL":
        return `
        .inner{
          width: 100vw !important;
        }
        .margin{
          &,*{
            justify-content: center !important;
            align-items:center !important;
            text-align: center !important;
            vertical-align: middle !important;
          }
          flex-direction:column-reverse !important;
          
          .text{
            width:auto !important;
            .title{
              font-size: ${({ titleSize = "20px" }) =>
                `${titleSize}`} !important;
                width: auto !important;
            }
            .block-content{
              padding: 20px ${contentPadding} 26px !important;
            }
          }
          .block-img{
            ${MBOLImgPosition}
          }
        }
        `;
      default:
        return ``;
    }
  }}
`;
