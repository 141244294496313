import ChainX_logo from '../../assets/img/ChainXLogo.svg';
import Email from '../../assets/img/email.svg';
import Github from '../../assets/img/github.svg';
import Medium from '../../assets/img/Medium.svg';
import Telegram from '../../assets/img/telegram.svg';
import Twitter from '../../assets/img/twitter.svg';
import Wechat from '../../assets/img/wechat.svg';
import QR from '../../assets/img/wechat.png';
import lightPaper from '../../assets/Chainx.pdf';
import ComingChat from '../../assets/img/icon_comingchat.svg';

export const footerData = {
  background: '#0B0F12',
  textColor: 'rgba(255, 255, 255, .7)',
  hoverTextColor: 'rgba(255, 255, 255, 1)',
  imgPic: {
    imgSource: ChainX_logo,
    width: '140px',
    height: '28px',
  },
  reserved: 'All rights reserved © 2022 ChainX',
  friendLinks: [
    {
      title: 'Products',
      children: [
        {
          name: 'Apps',
          linkTo: '/wallet'
        },
        // {
        //   name: 'Soswap',
        //   href: 'https://soswap.finance/'
        // },
        {
          name: 'BTC DAO',
          href: ' '
        },
        {
          name: 'Discord',
          href: 'https://discord.gg/8pkfpUssxD'
        },
        {
          name: 'Comfuture',
          href: 'https://comfuture.coming.chat/'
        }
      ]
    },
    {
      title: 'Tools',
      children: [
        {
          name: 'ChainX APPs',
          href: 'https://dapp.chainx.org/'
        },
        // {
        //   name: 'SherpaX Apps',
        //   href: 'https://apps.sherpax.io/#/accounts'
        // },
        {
          name: 'ChainX Explorer',
          href: 'https://scan.chainx.org/'
        },
        // {
        //   name: 'SherpaX Explorer',
        //   href: 'https://scan.sherpax.io/'
        // },
        // {
        //   name: 'SherpaX-EVM Explorer',
        //   href: 'https://evm.sherpax.io/'
        // },
        {
          name: 'MiniX Explorer',
          href: 'https://miniscan.coming.chat/',
        },
        {
          name: 'ChainX Wallet',
          linkTo: '/wallet',
        }
      ]
    },
    {
      title: '',
      children: [
        {
          title: 'Networks',
          children: [{
            name: 'ChainX',
            href: ''
          },
            // {
            //   name: 'SherpaX',
            //   href: 'https://www.sherpax.io/'
            // },
          ]
        },
        {
          title: 'Doc',
          children: [{
            name: 'Light paper',
            href: lightPaper
          },
            {
              name: 'Help Center',
              href: 'https://chainx-org.github.io/documentation'
            }]
        }
      ]
    },
    {
      title: 'Community',
      path: '/community',
      children: [
        {
          name: 'ChainX Ambassador',
          href: '',
          linkTo: ''
        },
        {
          name: 'Code Contributions',
          href: 'https://github.com/chainx-org'
        },
        {
          name: 'Run a node',
          href: 'https://chainx-org.github.io/documentation/docs/chainx/developers/devops/'
        },
        {
          name: 'Build Dapp',
          href: ''
        }
      ]
    },
  ],
  IconList: [
    {
      title: 'Twitter',
      img: Twitter,
      linkTo: 'https://twitter.com/chainx_org'
    },
    {
      title: 'Medium',
      img: Medium,
      linkTo: 'https://medium.com/@chainx-org'
    },
    {
      title: 'Telegram',
      img: Telegram,
      linkTo: 'https://t.me/chainx_org'
    },
    {
      title: 'Github',
      img: Github,
      linkTo: 'https://github.com/chainx-org/ChainX'
    },
    {
      title: 'ComingChat',
      img: ComingChat,
      linkTo: 'https://www.coming.chat/group/#EjgKIAmf7YUF1yU-3XLfjj0bVTL_ym5HrMBIFnA6ybQt7ZVREhD19ISDFVLWUkqRvwb3LPfEGOuuAg'
    }
  ],
  wechat: {
    title: 'wechat',
    img: Wechat,
    QRcode: QR
  },
  email: {
    title: 'email',
    img: Email
  }
};
