import React from 'react';

export const arrowSvg = <svg width="13px" height="12px" viewBox="0 0 13 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
  <title>arrow_default</title>
  <g id="3.0" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="导航2_product" transform="translate(-667.000000, -215.000000)">
      <g id="编组-9" transform="translate(180.500000, 61.000000)">
        <g id="arrow_default" transform="translate(487.000000, 154.000000)">
          <rect id="矩形" fill="#D8D8D8" opacity="0" x="0" y="0" width="12" height="12"></rect>
          <g id="编组-3" transform="translate(1.757359, 1.757359)" stroke="#1B1B1B" stroke-linecap="round">
            <line x1="0" y1="0" x2="8.48528137" y2="8.48528137" id="路径-4"></line>
            <polyline id="路径-5" stroke-linejoin="round"
                      points="0.565685425 8.48528137 8.48528137 8.48528137 8.48528137 0.565685425"></polyline>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>;

export const titleArrow = <svg
  width="9px"
  height="6px"
  viewBox="0 0 9 6"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
>
  <title>select</title>
  <g
    id="3.0备份"
    stroke="none"
    strokeWidth="1"
    fill="none"
    fillRule="evenodd"
  >
    <g
      id="首页"
      transform="translate(-513.000000, -26.000000)"
      fill="#FFFFFF"
      fillRule="nonzero"
    >
      <g
        id="编组-3"
        transform="translate(418.000000, 19.000000)"
      >
        <g
          id="下拉"
          transform="translate(95.000000, 7.000000)"
        >
          <path
            d="M8.79220144,0.482558827 C8.64610752,0.336464907 8.45131563,0.287766933 8.25652373,0.287766933 L0.951827733,0.336464907 C0.75703584,0.336464907 0.562243947,0.38516288 0.416150027,0.5312568 C0.123962187,0.82344464 0.123962187,1.2617264 0.416150027,1.55391424 L4.06849803,5.20626224 C4.06849803,5.20626224 4.06849803,5.20626224 4.06849803,5.20626224 C4.06849803,5.20626224 4.06849803,5.20626224 4.06849803,5.20626224 C4.117196,5.25496021 4.21459195,5.30365819 4.26328992,5.35235616 C4.26328992,5.35235616 4.31198789,5.40105413 4.31198789,5.40105413 C4.55547776,5.49845008 4.89636357,5.44975211 5.09115547,5.25496021 L8.74350347,1.55391424 C9.08438928,1.21302843 9.03569131,0.774746667 8.79220144,0.482558827 Z"
            id="路径"
          ></path>
        </g>
      </g>
    </g>
  </g>
</svg>;