import styled from "styled-components";

export const HeaderWrapper = styled.div`
  height: 56px;
  padding: 0 2.5rem;
  width: 100%;
  &.homepage {
    position: fixed;
    top: 0;
    background: #000;
    z-index: 12;
  }
  &.otherpage {
    position: fixed;
    top: 0;
    background: #000;
    z-index: 12;
  }
  @media screen and (max-width: 550px) {
    justify-content: space-between;
    padding: 0 1.75rem;
  }
  .containers {
    width: 100%;
    @media screen and (min-width: 1290px) {
      width: 1280px;
      margin: auto;
    }
    .web {
        margin-left: 6rem;
        @media screen and (max-width: 550px) {
            display: none;
        }
    }
    .ChainX_logo {
        img {
          z-index: 1001;
          @media screen and (max-width: 550px) {
            width: 114px;
            height: 22px;
          }
        }
    }
  }
  .list {
    flex-direction: row;
    .item {
       position: relative; 
       padding: 16px;
       color: #FFF;
       font-size: 16px;
       cursor: pointer;
       &:hover .title-item {
        color: #FEE32B;
        svg {
          g {
            fill: #FEE32B;
          }
        }
      }
      #menuDoc{
        transform: translateX(-37%);
      }
      .menu {
        position: absolute;
        transform: translateX(-50%);
        top: 66px;
        width: fit-content;
        padding: 0;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 15px rgba(0,0,0,0.08);
        transition: all .3s ease-in;
        opacity: 0;
        visibility: hidden;
        z-index: 10;
          .item {
          padding: 12px 0 12px 24px;
          font-size: 16px;
          font-weight: 500;
          color: #1B1B1B;
          line-height: 16px;
          .imgBg {
            width: 32px;
            height: 32px;
            background: #EEEEEE;
            border-radius: 6px;
            margin-right: 16px;
          }
          &:hover {
            width: fit-content;
            background: rgba(238,238,238,.54);
            border-radius: 10px 10px;
            font-weight: bold;
            .imgBg {
              background: #FFE742;
            }
            .Network {
              background: #000;
            }
          }
        }
      }
      &.hoverable-menu:hover .menu {
        top: 54px;
        opacity: 1;
        // transform: perspective(1000px) translate3d(0, 0, 0);
        width: fit-content;
        visibility: visible;
        z-index: 98;
      }
      .title-item {
        position:relative;
        user-select: none;
        >img{
          position: absolute;
          transform: translate(100%,155%);
        }
        svg {
            margin-left: 10px;
        }
        &:before,
        &:after {
          content: "";
          position: absolute;
          width: 0px;
          height: 2px;
          margin: 5px 0 0;
          transition: all .3s cubic-bezier(0.340, 0.370, 0.055, 1.560);
          opacity: 1;
          background-color: #FEE32B;
          left: 0;
          bottom: -6px;
        } 
        .DownWhite {
          margin: 0 6px;
        }
      }
      &:hover {
        .title-item {
          &:before,
            &:after {
              width: 100%;
              opacity: 1;
          }
        }
      }
    }
  }
`;

export const MobileDataBox = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  &.mob {
    @media screen and (min-width: 550px) {
      display: none;
    }
  }
  display: flex;
  justify-content: flex-end;
  a {
    text-decoration: none;
  }
 
`;

export const MenuPopUp = styled.div<{isTool?:boolean,isCol?:boolean}>`
  display: flex;
  flex-direction:  ${({ isCol }) => isCol?'column':'row'};
  padding:  ${({ isCol }) => isCol?'16px':''};
  .menuList{
    display: flex;
    flex-direction: column;
    padding:  ${({ isCol }) => isCol?'8px 0':'24px'};
    width:${({ isCol }) => isCol?'150px':'fit-content'};
    .title{
      padding: ${({ isTool }) => isTool?'0 0 4px':'0 0 24px'};
      font-size: 14px;
      font-weight: 500;
      color: #1B1B1B;
      line-height: 16px;
    }
    .content{
      min-width: 210px;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      padding-top: 4px;
    }
    .childItem{
      display: flex;
      flex-direction: row;
      align-items: ${({ isTool,isCol }) => isTool || isCol?'center':''};
      padding: ${({ isTool,isCol }) => isTool?'8px 0':isCol?'0':'0 0 35px 0'};
      >img{
        position: relative;
        top: 0;
        height: 32px;
        width: 32px;
        margin-right: 8px;
      }
      .container{
        display: flex;
        flex-direction: column;
        color: #1B1B1B;
        &:hover {
          color: #FEE32B;
          svg {
            g {
              stroke: #FEE32B;
            }
          }
        }
        .name{
          font-size: 16px;
          font-weight: 500;
          line-height: 22px;
          padding-bottom: ${({ isTool,isCol }) => isTool || isCol?'0px':'4px'};
          display: flex;
          flex-direction: row;
          align-items: center;
          >span{
            white-space: pre;
            padding-right: 2px;
          }
        }
        .content{
          font-size: 12px;
          font-weight: 400;
          color: #999999;
          line-height: 17px;
          :hover{
            color:#F6C94A !important;
          }
        }
      }
      &:hover{
        color: #F6C94A !important;
      }
    }
  }
`
